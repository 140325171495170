<template>
  <div class="news">
    <div class="headImg">
      <Nav nums='4' isColor='#fff'/>
      <div class="joinBanner">
           <Video :bannerArr='coverBanner'/>
         </div>
    </div>
    <div class="posts">
      <div class="title">
        <span class="icon"></span>
        <span class="titleName">招聘岗位</span>
      </div>
      <div class="postsCont">
        <div class="cont" v-for="(item,index) in postsList" :key="index">
         <div>
           <div class="contTitle">{{item.name}} {{item.min_price}}-{{item.max_price}} {{item.num}}人 {{item.location}}</div>
          <div class="postState">
            <!-- <p>职位描述：</p> -->
            <div v-html="item.obligation">
              {{item.obligation}}
              </div>
          </div>
         </div>
         <div class="btn">
           <div class="checkBtn">
             <router-link :to="{path:'/join',query:{id:item.id}}">查看详情</router-link>
           </div>
         </div>
        </div>
      </div>
    </div>
    <Floor/>
  </div>
</template>

<script>
import Nav from './nav.vue'
import Floor from './floor.vue'
import { imgHttp, request } from '../apis/http'
import Video from '../components/video.vue'
export default {
  components: {
    Nav,
    Floor,
    Video
  },
  data () {
    return {
      postsList: [],
      coverBanner:[],
      bannerVideo:true,
      bannerImg:[]
    }
  },
  beforeMount() {
    this.recruitlist()
    this.bigBanner()
    scrollTo(0, 0)
  },
  methods:{
    async recruitlist(){
      const res = await request({
        url:'/api/index/recruitlist'
      })
      this.postsList = res.data.data
    },
     async bigBanner () {
      const res = await request({
        url: '/api/index/cover?type=5'
      })
       const bannerNewArr = res.data.data.map(i => {
        if (i.images !== ''){
            i.images = i.images.split(',').map(r => {
                r = imgHttp + r
                return r
            })
          }
          if (i.videos !== ''){
              i.videos = i.videos.split(',').map(r => {
              r = imgHttp + r
              return r
          })
          }
          i.arr = [...i.videos, ...i.images]
        return i
      })
        this.coverBanner = bannerNewArr[0].arr
   }
  }
}
</script>
<style lang="less">
@import './fn.less';
  .headImg{
      width: 100%;
      height: 46vw;
      position: relative;
      .joinBanner{
        width: 100%;
        height: 46vw;
        position: absolute;
        top: 0;
        left: 0;
        .el-carousel .el-carousel__indicators{
        flex-direction: row;
      }
      }
  }
  .posts{
    display: flex;
    flex-direction: column;
    align-items: center;
    .title{
      width: 62.5vw;
      margin-top: 5.44791667vw;
      .icon{
      display: inline-block;
      width: 0.3645833vw;
      height: 1.40625vw;
      background:#00538D;
     }
     .titleName{
       color: #101218;
       font-size: 1.5625vw;
       font-weight: 700;
       margin-left: 0.9375vw;
     }
    }
    .postsCont{
      width: 56.25vw;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 2.0458333vw;
      .cont{
        width: 26.5625vw;
        background: #F6F7F9;
        border-radius: 1.354166vw;
        padding: 2.083333vw 1.8229166vw 1.5625vw;
        box-sizing: border-box;
        position: relative;
        margin-bottom: 2.604166vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .contTitle{
          color: #101218;
          font-size: 1.3020833vw;
          font-weight: 700;
        }
        .postState{
          margin-top: 1.04166vw;
          color: #6C7388;
          font-size: 0.83333vw;
          line-height: 1.1979166vw;
          p{
            font-size: 0.83333vw!important;
            line-height: 1.04266vw;
          }
        }
        .btn{
          width: 100%;
          display: flex;
          justify-content: flex-end;
          .checkBtn{
          width: 5.5291666vw;
          height: 1.6229166vw;
          border-radius: 0.9375vw;
          background: #00538D;
          text-align: center;
          line-height: 1.6229166vw;
          font-size: 0.8375vw;
          a{
            color: #fff;
          }
          &:hover{
            opacity: .7;
          }
         }
        }
      }
    }
  }
  @media screen and (max-width: 980px) {
    .headImg{
      width: 100%;
      .joinBanner{
        .vvw(height,500);
        margin-top: 5vw;
      }
   }
  .posts{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .vvw(padding,0,110,0);
    box-sizing: border-box;
    .title{
      width: 100%;
      margin-top: 5.44791667vw;
      .icon{
      display: inline-block;
      width: 1.3645833vw;
      height: 3.40625vw;
      background:#00538D;
     }
     .titleName{
       color: #101218;
       .vvw(font-size,50);
       font-weight: 700;
       margin-left: 1.9375vw;
     }
    }
    .postsCont{
        width: 100%;
      flex-direction: column;
      margin-top: 2.0458333vw;
      .cont{
        width: 100%;
        background: #F6F7F9;
        border-radius: 1.354166vw;
        padding: 3.083333vw 1.8229166vw 2.5625vw;
        margin-bottom: 2.604166vw;
         .contTitle{
          color: #101218;
          .vvw(font-size,40);
          font-weight: 700;
        }
        .postState{
          margin-top: 2.04166vw;
          color: #6C7388;
         .vvw(font-size,24);
          line-height: 1.1979166vw;
          p{
            font-size: 2.448979vw!important;
            line-height: 5.04266vw;
          }
          span{
            .vvw(font-size,24);
             line-height: 5.04266vw;
          }
        }
        .btn{
          width: 100%;
          display: flex;
          justify-content: flex-end;
          .checkBtn{
          width: 20.5291666vw;
          height: 6.6229166vw;
          border-radius: 0.9375vw;
          background: #00538D;
          text-align: center;
          line-height: 6.6229166vw;
         .vvw(font-size,24);
          a{
            color: #fff;
          }
          &:hover{
            opacity: .7;
          }
         }
        }
      }
    }
  }
  }
</style>
